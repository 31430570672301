import styled from "styled-components"
import useMediaQuery from "@/hooks/useMediaQuery"

function getThemeBP(bp) {
  // if (typeof bp === "string" && theme.breakpoints[bp]) {
  //   return theme.breakpoints[bp];
  // }
  return null
}

const MediaQueryRoot = styled.div(props => {
  let { minWidth, maxWidth, display = "block" } = props
  const styles = {
    display: "none",
  }

  if (minWidth) {
    const bp = getThemeBP(minWidth)
    styles[`@media (min-width: ${bp || minWidth}px)`] = {
      display,
    }
  }

  if (maxWidth) {
    const bp = getThemeBP(maxWidth)
    styles[`@media (max-width: ${bp || maxWidth}px)`] = {
      display,
    }
  }

  return styles
})

const MediaQuery = ({ children, minWidth, maxWidth, ...rest }) => {
  const isMax = useMediaQuery({ maxWidth })
  const isMin = useMediaQuery({ minWidth })

  const shouldRender = (maxWidth && isMax) || (minWidth && isMin)

  return shouldRender ? (
    <MediaQueryRoot maxWidth={maxWidth} minWidth={minWidth} {...rest}>
      {children}
    </MediaQueryRoot>
  ) : null
}

export default MediaQuery
