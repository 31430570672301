import { Fragment } from "react"

import { graphql } from "gatsby"

import { HeaderMobile } from "@/components/Header"
import MediaQuery from "@/components/MediaQuery"
import Seo from "@/components/Seo"
import useMediaQuery from "@/hooks/useMediaQuery"
import DocumentSection, { PolicyDocument } from "@/sections/DocumentSection"
import {
  FooterSectionDesktop,
  FooterSectionMobile,
} from "@/sections/FooterSection"
import {
  PageHeaderSectionDesktop,
  PageHeaderSectionMobile,
} from "@/sections/PageHeaderSection"

export default function PrivacyPolicy({ data }) {
  const { file, strapi } = data
  const { gatsbyImageData, shareImage } = file.childImageSharp
  const isMD = useMediaQuery({ maxWidth: 1024 })

  const document = (
    <DocumentSection>
      <PolicyDocument />
    </DocumentSection>
  )

  return (
    <Fragment>
      <Seo
        title={strapi.privacyPolicy.seo.metaTitle}
        description={strapi.privacyPolicy.seo.metaDescription}
        image={shareImage}
        pathname="/privacy-policy"
      />

      {isMD ? (
        <Fragment>
          <HeaderMobile />
          <PageHeaderSectionMobile
            fluidImage={gatsbyImageData}
            title={strapi.privacyPolicy.hero.title}
          />
          {document}
          <FooterSectionMobile />
        </Fragment>
      ) : (
        <Fragment>
          <PageHeaderSectionDesktop
            fluidImage={gatsbyImageData}
            title={strapi.privacyPolicy.hero.title}
            animateTitle={false}
          />
          {document}
          <FooterSectionDesktop />
        </Fragment>
      )}
    </Fragment>
  )
}

export const query = graphql`
  {
    strapi {
      privacyPolicy {
        seo {
          ...seo
        }
        hero {
          ...hero
        }
        content
        pdf {
          url
        }
      }
    }
    file(relativePath: { eq: "notaries-header.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        shareImage: gatsbyImageData(
          quality: 100
          width: 1200
          height: 687
          layout: FIXED
        )
      }
    }
  }
`
